/* eslint-disable no-console */
import { notification } from 'antd';
import { getCookie } from 'cookies-next';
import QueryString from 'qs';

import { getLinkGame, profile, updateUserInfo } from '@/lib/apis';
import { ICasino } from '@/lib/dto';
import Notification from '@/lib/notification';
import { isMobile, transformParamsByProvider } from '@/lib/utils';

import { useGameModalStore } from '@/store/zustand';

// Hàm để thêm một item mới vào danh sách game đã xem

const checkChangeFullName = async (item: ICasino) => {
  if (['rik', 'b52'].includes(item.partner_provider)) {
    const user = await profile();
    if (user?.fullname === user?.username) {
      Notification.fire({
        title: 'Vui lòng đặt tên hiển thị',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
          placeholder: 'Từ 6-30 ký tự gồm chữ thường và số',
        },
        showCloseButton: true,
        confirmButtonText: 'Cập nhật',
        showLoaderOnConfirm: true,
        preConfirm: async (username) => {
          const usernameRegex = /^[a-z0-9]{6,30}$/;
          if (user?.username === username) {
            Notification.sw.showValidationMessage(
              'Tên hiển trùng với tên đăng nhập!'
            );
            return;
          }
          if (!usernameRegex.test(username)) {
            Notification.sw.showValidationMessage(
              'Tên hiển thị phải từ 6-30 ký tự gồm chữ thường và số.'
            );
            return;
          }
          const res = await updateUserInfo({ fullname: username.trim() });
          if (res.code !== 200) {
            Notification.sw.showValidationMessage(res.msg || '');
          }
        },
        allowOutsideClick: () => Notification.sw.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Notification.fire({
            title: 'Cập nhật tên thành công',
            icon: 'success',
          });
        }
      });
      console.log('2:🔥', 2);
      return false;
    } else {
      console.log('1:🔥', 1);
      return true;
    }
  }
  console.log('3:🔥', 3);
  return true;
};

const idsGameWithoutLink = [
  'mn_1005',
  'mn_1008',
  'mn_1009',
  'vgld_1005',
  'features_02',
  'thailottery',
  'vsthai',
  'Yeekee',
];
const idsIframeWeb = [
  'features_02',
  'lottery01',
  'vgld_1005',
  'mn_1005',
  'mn_1008',
  'mn_1009',
  'thailottery',
  'vsthai',
  'Yeekee',
];
export const dataGameIframe: any = {
  vgld_1005: {
    link: 'https://assets.vgjt.info/js/ld3m.js',
    tag: `<c2-lode3m token="$" login="loginUrl" lang="th"  color="#009643" ></c2-lode3m>`,
    title: 'Lô Đề 3 Miền',
    description: '',
  },
  mn_1008: {
    link: 'https://assets.vgjt.info/js/vietlott.js',
    tag: `<c2-mega645 token="$" login="loginUrl" lang="th"  color="#006622"></c2-mega645>`,
    title: 'Jackpot Mega 645',
    description: '',
  },
  mn_1009: {
    link: 'https://assets.vgjt.info/js/vietlott.js',
    tag: `<c2-power655 token="$" login="loginUrl" lang="th"  color="#006622"></c2-power655>`,
    title: 'Jackpot Power 655',
    description: '',
  },
  mn_1005: {
    link: 'https://assets.vgjt.info/js/ldmd5.js',
    tag: `<c2-ldmd5 token="$" login="loginUrl" lang="th"  ></c2-ldmd5>`,
    title: 'Lô Đề Siêu Tốc',
    description: '',
  },
  features_02: {
    link: 'https://assets.vgjt.info/js/ft.js',
    tag: `<c2-future token="$" login="loginUrl" lang="th" ></c2-future>`,
    title: 'Feature Tiền ảo',
    description: '',
  },
  thailottery: {
    link: 'https://assets.vgjt.info/js/xsthai.js',
    tag: `<c2-thailottery token="$" login="loginUrl" lang="th" ></c2-thailottery>`,
    title: 'ThaiLottry',
    description: '',
  },
  vsthai: {
    link: 'https://assets.vgjt.info/js/vsthai.js',
    tag: `<c2-vsthai token="$" login="loginUrl" lang="th"></c2-vsthai>`,
    title: 'VSthai',
    description: '',
  },
  yeekee: {
    link: 'https://assets.vgjt.info/js/ykmd5.js',
    tag: `<c2-yeekee-md5 token="$" login="loginUrl" lang="th"></c2-yeekee-md5>`,
    title: 'Yeekee',
    description: '',
  },
};

export const getLinkHardCode = (item: ICasino) => {
  if (idsIframeWeb.includes(item.partner_game_id)) {
    const newWin: any = window.open('about:blank', '_blank');
    const lang = getCookie('i18next') || '';
    console.log('lang:', lang);
    newWin.location.href = `${window.location.origin}/${lang}/game/link/${item?.partner_game_id}`;
  }
};

export const openGame = async (item: ICasino) => {
  const token = getCookie('token');
  // Nếu bắt buộc đăng nhập mà vẫn không có token thì đá lỗi
  if (!token && item?.is_require_login) {
    if (window?.popup) {
      window?.popup?.login();
    } else {
      Notification.fire({
        title: 'Thông báo',
        text: 'Hãy đăng nhập để được tham gia',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Close',
      });
    }
    return;
  }

  //Hande HardCode game out scope
  if (idsGameWithoutLink.includes(item.partner_game_id)) {
    getLinkHardCode(item);
    return;
  }

  if (!item.is_new_tab) {
    const queryParams = QueryString.stringify(
      {
        partner_provider: item.partner_provider,
        partner_game_id: item.partner_game_id,
        game: Buffer.from(JSON.stringify(item)).toString('base64'),
      },
      { encode: false }
    );
    window.open(`/game/play?${queryParams}`);
    return;
  }
  // const newWin: any = window.open('about:blank', '_blank');
  const checkValidName = await checkChangeFullName(item);
  if (!checkValidName) {
    // newWin.close();
    return;
  }
  try {
    const res = await getLinkGame({
      partner_provider: item.partner_provider,
      category_name: item.partner_game_id,
      device: isMobile() ? 'mobile' : 'pc',
    });
    console.log('res:', res);
    if (res.code === 200) {
      // newWin.location.href = res.data;
      // window.gameContainer?.openGameModal(res.data, item);
    } else {
      // newWin.close();
      // TODO: test
      // const statusMsg = await getTranslation([
      //   `${res?.status || ''}` || '',
      //   `${res?.code || ''}` || '',
      //   'UNKNOWN_ERROR',
      // ]);
      const statusMsg = res?.status;
      Notification.popup({
        type: 'error',
        title: 'Notification',
        description: statusMsg || res?.message,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Close',
      });
    }
  } catch (e) {
    notification.error({
      message: String(e),
    });
    console.error('logs:e', e);
    // alert('Có lỗi sảy ra');
  }
};
export const openGameProvider = async (
  item?: ICasino | any,
  router?: any,
  isForceOpenNewTab = isMobile()
) => {
  //Nếu không có Lobby thì đá page Provider
  if (item?.lobbyCate && router) {
    router.push(
      '/game/provider/' + item?.partner_provider + '/' + item?.lobbyCate || '0'
    );
    return;
  }
  const token = getCookie('token');
  // Nếu bắt buộc đăng nhập mà vẫn không có token thì đá lỗi
  console.error('item?.is_require_login:🔥', item?.is_require_login);
  if (!token && item?.is_require_login === true) {
    if (window?.popup) {
      window?.popup?.login();
    } else {
      Notification.fire({
        title: 'Thông báo',
        text: 'Hãy đăng nhập để được tham gia',
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Close',
      });
    }
    return;
  }
  //Hande HardCode game out scope
  if (idsGameWithoutLink.includes(item.partner_game_id)) {
    getLinkHardCode(item);
    return;
  }
  if (item?.isHotMatch) {
    const queryParams = QueryString.stringify(
      {
        partner_provider: item.partner_provider,
        partner_game_id: item.partner_game_id,
        game: Buffer.from(JSON.stringify(item)).toString('base64'),
        match_id: item.event_id,
        league_id: item.league_id,
        // match_id: item.id,
      },
      { encode: false }
    );

    const link = `/game/play?${queryParams}`;

    if (router) {
      router.push(link);
      return;
    }
    window.location.href = link;
    return;
  }
  if (item?.is_new_tab === false && !isMobile) {
    const queryParams = QueryString.stringify(
      {
        partner_provider: item.partner_provider,
        partner_game_id: item.partner_game_id,
        category_name: item.category_name,
        game: Buffer.from(JSON.stringify(item)).toString('base64'),
        ...item,
      },
      { encode: false }
    );

    const link = `/game/play?${queryParams}`;

    if (router) {
      router.push(link);
      return;
    }
    window.location.href = link;
    return;
  }

  let newWin: any;
  if (isForceOpenNewTab) {
    newWin = window.open('about:blank', '_blank');
  }
  const checkValidName = await checkChangeFullName(item);
  if (!checkValidName) {
    return;
  }

  //if open detailgame
  console.log('item?.detail:', item?.detail);
  if (item?.detail) {
    openGame(item);
    return;
  }

  try {
    const params = transformParamsByProvider(item);
    useGameModalStore.getState().setGameLoading(item?.id);
    const res = await getLinkGame(params);
    console.log('res:', res);
    if (res.code === 200) {
      if (isForceOpenNewTab && newWin) {
        newWin.location.href = res.data;
        return;
      }

      useGameModalStore
        .getState()
        .setGameInfo({ ...(item || {}), src: res.data || '' });

      useGameModalStore.getState().setGameLoading(0);
      useGameModalStore.getState().setLargeSize(true);
    } else {
      if (newWin) newWin.close();

      // const statusMsg = await getTranslation([
      //   `${res?.status || ''}` || '',
      //   `${res?.code || ''}` || '',
      //   'UNKNOWN_ERROR',
      // ]);

      const statusMsg = res?.status;
      Notification.popup({
        type: 'error',
        title: 'Notification',
        description: statusMsg || res?.message,
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Close',
      });
    }
  } catch (e) {
    notification.error({
      message: String(e),
    });
    console.error('logs:e', e);
    // alert('Có lỗi sảy ra');
  } finally {
    useGameModalStore.getState().setGameLoading(0);
  }
};
